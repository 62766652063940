/* eslint-disable */
import axios from '@axios';
import { HTTP } from '@/libs/axios';
import { errorsHandler, successMessage } from '@/helpers/errorsHandler.js';

const state = () => {
	return {
		userRoles: null,
		admins: null,
	};
};

const getters = {};

const mutations = {
	SET_USER_ROLES(state, response) {
		let data;
		if (response.data) {
			data = response.data.roles;
			state.userRoles = data.map((obj) => ({
				roleId: obj.id,
				text: obj.name,
				value: obj.name,
				isEnabled: obj.isEnabled,
			}));
		} else {
			state.userRoles = null;
		}
	},
	SET_ADMINS(state, response) {
		state.admins = response.data.admins.map((obj) => ({
			id: obj.id,
			firstName: obj.firstName,
			lastName: obj.lastName,
			email: obj.email,
			mobile: obj.mobile,
		}));
	},
	CLEAR_USER_ROLES(state) {
		state.userRoles = null;
	},
};
const actions = {
	async getUserRole({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/role-management/admins/${payload.id}/roles`, {
				email: payload.email,
			})
				.then((response) => {
					commit('SET_USER_ROLES', response.data);
					if (response.data.error) errorsHandler(response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async setUserRole(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(`/role-management/admins/${payload.id}/roles`, {
				roles: payload.roles,
			})
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'User Roles Saved');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getAdmins({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/role-management/admins`)
				.then((response) => {
					commit('SET_ADMINS', response.data);
					if (response.data.error) errorsHandler(response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
