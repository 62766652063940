/* eslint-disable */
import axios from '@axios';
import { HTTP } from '@/libs/axios';
import { errorsHandler, successMessage } from '@/helpers/errorsHandler.js';

const state = () => {
	return {
		categoryManagers: null,
		categoryManagersWithVendors: null,
	};
};

const getters = {};

const mutations = {
	SET_CATEGORY_MANAGERS(state, response) {
		state.categoryManagers = response;
	},
	SET_CATEGORY_MANAGER_WITH_VENDORS(state, response) {
		state.categoryManagersWithVendors = response;
	},
};
const actions = {
	async setCategoryRoles(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(
				`/category-manager-roles/category-managers/${payload.id}/vendors`,
				{
					vendors: payload.vendors,
				}
			)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Vendors Saved');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getCategoryManagers({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/category-manager-roles/category-managers`)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					commit('SET_CATEGORY_MANAGERS', response.data);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getCategoryManagerWithVendors({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(
				`/category-manager-roles/category-managers/${payload.id}/vendors`
			)
				.then((response) => {
					commit('SET_CATEGORY_MANAGER_WITH_VENDORS', response.data);
					if (response.data.error) errorsHandler(response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
