/* eslint-disable */
import axios from '@axios';
import { HTTP } from '@/libs/axios';
import {errorsHandler, successMessage } from '@/helpers/errorsHandler.js';

const state = () => {
	return {
		vendors: [],
		applications: null,
		redirectUrl: null,
		contractData: null,
	};
};

const getters = {};

const mutations = {
	SET_VENDORS(state, response) {
		state.vendors = response.data;
	},
	SET_APPLICATIONS(state, response) {
		state.applications = response.data;
	},
	SET_REDIRECTURL(state, response) {
		console.log(response);
		state.redirectUrl = response.data.data.url;
	},
	SET_CONTRACT(state, response) {
		state.contractData = response.data.data;
	},
};
const actions = {
	async getVendors({ commit }, payload) {
		let params = payload.take ? 'take=' + payload.take : 'take=-1';
		params += payload.skip ? '&skip=' + payload.skip : '&skip=0';
		params += payload.searchKey ? '&searchKey=' + payload.searchKey : '';

		return new Promise((resolve, reject) => {
			HTTP.get(`/vendor-management/vendors?` + params)
				.then((response) => {
					commit('SET_VENDORS', response);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getImpersonateUrl({ commit }, email) {
		const payload = { email };
		console.log(payload);
		return new Promise((resolve, reject) => {
			HTTP.post(`/vendor-management/vendor-cms-admin-session`, payload)
				.then((response) => {
					commit('SET_REDIRECTURL', response);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getApplications({ commit }, payload) {
		let params = payload.take ? 'take=' + payload.take : 'take=-1';
		params += payload.skip ? '&skip=' + payload.skip : '&skip=0';
		params += payload.searchKey ? '&searchKey=' + payload.searchKey : '';

		return new Promise((resolve, reject) => {
			HTTP.get(`/vendor-management/applications?` + params)
				.then((response) => {
					commit('SET_APPLICATIONS', response);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async deleteVendor({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.delete(`/vendor-management/vendors/${payload.id}`)
				.then((response) => {
					commit('SET_VENDORS', response);
					if (response.data.error) errorsHandler(response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async resetVendorPassword(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(`/vendor-management/vendors/${payload.id}/password`)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async setVendorApplicationStatus(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(`/vendor-management/applications/${payload.id}/status`, {
				status: payload.status,
			})
				.then((response) => {
					console.log(response.data.error);
					if (response.data.error) return errorsHandler(response.data.error);
					else successMessage('Successfully', 'User Deleted');
				resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async setVendorAttachement(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(
				`/vendor-management/vendors/${payload.id}/contractAttachment`,
				payload.formData
			)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getVendorAttachement({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/vendor-management/vendors/${payload}/contractAttachment`)
				.then((response) => {
					commit('SET_CONTRACT', response);
					if (response.data.error) errorsHandler(response.data.error);
					else window.open(response.data.data.url);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
