/* eslint-disable */
import axios from '@axios';
import { HTTP } from '@/libs/axios';
import { errorsHandler, successMessage } from '@/helpers/errorsHandler.js';

const state = () => {
	return {
		customers: [],
		isTableBusy: true,
	};
};

const getters = {};

const mutations = {
	SET_CUSTOMERS(state, response) {
		state.customers = response.data.data.customers;
	},
	SET_ISTABLEBUSY(state, val) {
		state.isTableBusy = val;
	},
};

const actions = {
	async getCustomers({ commit }, payload) {
		let params = payload.take ? 'take=' + payload.take : 'take=-1';
		params += payload.skip ? '&skip=' + payload.skip : '&skip=0';
		params += payload.searchKey ? '&searchKey=' + payload.searchKey : '';

		return new Promise((resolve, reject) => {
			commit('SET_ISTABLEBUSY', true);
			HTTP.get(`/customer-management/customers?` + params)
				.then((response) => {
					commit('SET_ISTABLEBUSY', false);
					commit('SET_CUSTOMERS', response);
					resolve(response);
				})
				.catch((error) => {
					commit('SET_ISTABLEBUSY', false);
					console.log(error);
					reject(error);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
