/* eslint-disable */
import axios from '@axios';
import { HTTP } from '@/libs/axios';
import { errorsHandler, successMessage } from '@/helpers/errorsHandler.js';

const state = () => {
	return {
		regions: null,
		region: null,

		categories: null,
		subCategories: null,
		category: null,

		productAttributes: null,
		allAttributes: null,
	};
};

const getters = {};

const mutations = {
	SET_REGIONS(state, response) {
		state.regions = response;
	},
	SET_REGION(state, response) {
		state.region = response;
	},
	SET_CATEGORIES(state, response) {
		state.categories = response;
	},
	SET_SUB_CATEGORIES(state, response) {
		state.subCategories = response;
	},
	SET_CATEGORY(state, response) {
		state.category = response;
	},
	CLEAR_CATEGORY(state) {
		state.category = null;
	},
	SET_PRODUCT_ATTRIBUTES(state, response) {
		state.productAttributes = response;
	},
	PUSH_REGION(state, response) {
		state.regions.data.regions.push(response);
	},
	SET_ATTRIBUTES(state, response) {
		state.allAttributes = response.data;
	},
};

const actions = {
	async getRegions({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/parameters/regions`)
				.then((response) => {
					commit('SET_REGIONS', response.data);
					if (response.data.error) errorsHandler(response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getRegion({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/parameters/regions/${payload.id}`)
				.then((response) => {
					commit('SET_REGION', response.data);
					if (response.data.error) errorsHandler(response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async createRegion(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(`/parameters/regions`, {
				region: payload,
			})
				.then((response) => {
					console.log(payload);
					// commit("PUSH_REGION", payload.cities);
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Region Created');

					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getCategories({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/parameters/productCategories`)
				.then((response) => {
					commit('SET_CATEGORIES', response.data);
					if (response.data.error) errorsHandler(response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getCategory({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/parameters/productCategories/${payload.id}`)
				.then((response) => {
					commit('SET_CATEGORY', response.data);
					if (response.data.error) errorsHandler(response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getSubCategories({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/parameters/productSubCategories`)
				.then((response) => {
					commit('SET_SUB_CATEGORIES', response.data);
					if (response.data.error) errorsHandler(response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async createCategory(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(`/parameters/productCategories`, {
				productCategory: payload,
			})
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Caterory Created');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getProductAttributes({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(
				`/parameters/productSubCategories/${payload.id}/productAttributes`
			)
				.then((response) => {
					commit('SET_PRODUCT_ATTRIBUTES', response.data);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async createProductAttributes(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(
				`/parameters/productSubCategories/${payload.id}/productAttributes`,
				{
					attributes: payload.productAttributes,
				}
			)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Attribute Created');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getAllAttributes({ commit }) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/parameters/productAttributes`)
				.then((response) => {
					commit('SET_ATTRIBUTES', response.data);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async deleteAnyCategory({ commit }, payload) {
		const { id, type } = payload;
		const typesList = {
			category: 'productCategories',
			subCategory: 'productSubCategories',
			genericCategory: 'productGenericCategories',
		};
		return new Promise((resolve, reject) => {
			HTTP.delete(`/parameters/${typesList[type]}/${id}`)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
