/* eslint-disable */
import axios from '@axios';
import { HTTP } from '@/libs/axios';
import { errorsHandler, successMessage } from '@/helpers/errorsHandler.js';
import Swal from 'sweetalert2';

const state = () => {
	return {
		users: {},
		userModules: {},
	};
};

const getters = {};

const mutations = {
	SET_USERS(state, response) {
		let data = response;
		if (data) {
			state.users = data.map((obj) => ({
				id: obj.id,
				firstName: obj.firstName,
				lastName: obj.lastName,
				email: obj.email,
				mobile: obj.mobile,
			}));
		}
	},
	PUSH_USER(state, response) {
		state.users.push(response);
	},
	SET_USER_MODULES(state, response) {
		let data = response;

		state.userModules = data.map((obj) => ({
			id: obj.id,
			nameEng: obj.nameEng,
			nameRus: obj.nameRus,
			nameGeo: obj.nameGeo,
		}));
		state.userModules.push({
			id: 0,
			nameEng: 'Home',
			nameRus: null,
			nameGeo: null,
		});
	},
};

const actions = {
	async getUsers({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/admin-management/admins`)
				.then((response) => {
					commit('SET_USERS', response.data.data.admins);
					if (response.data.error) errorsHandler(response.data.error);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async registerUser({ commit }, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(`/admin-management/admins`, {
				email: payload.email,
				mobile: payload.mobile,
				firstName: payload.firstName,
				lastName: payload.lastName,
			})
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else {
						commit('PUSH_USER', payload);
						successMessage('Successfully', 'User Created');
					}
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getUserModules({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/auth/modules`)
				.then((response) => {
					commit('SET_USER_MODULES', response.data.data.modules);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async resetUserPassword(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(`/admin-management/admins/${payload.id}/password`)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', "User's Password Reseted");
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async setUserPassword(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.post(`/profile/password`, {
				email: payload.email,
				password: payload.password,
				repeatedPassword: payload.repeatedPassword,
			})
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', "User's Password Reseted");
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},

	async deleteUser(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.delete(`/admin-management/admins/${payload.id}`)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'User Deleted');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
