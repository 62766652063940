/* eslint-disable */
import axios from '@axios';
import { HTTP } from '@/libs/axios';
import { errorsHandler, successMessage } from '@/helpers/errorsHandler.js';

const state = () => {
	return {
		singleAddon: [],
		addons: [],
		addonCategories: [],
		showLoading: false,
	};
};

const getters = {};

const mutations = {
	SET_ADDONS(state, response) {
		console.log(response);
		state.addons = response.data.data.addon;
	},
	SET_ADDONCATEGORIES(state, response) {
		state.addonCategories = response.data.data.addOnCategories;
	},
	SET_SINGLEADDON(state, response) {
		state.singleAddon = response.data.data.addon;
	},
	SET_SHOW_LOADING(state, response) {
		state.showLoading = response;
	},
};

const actions = {
	async getAddons({ commit }, payload) {
		let params = payload.take ? 'take=' + payload.take : 'take=-1';
		params += payload.skip ? '&skip=' + payload.skip : '&skip=0';
		params += payload.searchKey ? '&searchKey=' + payload.searchKey : '';

		return new Promise((resolve, reject) => {
			HTTP.get(`/product-management/addons?` + params)
				.then((response) => {
					commit('SET_ADDONS', response);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async createAddon({ commit }, payload) {
		console.log(payload);
		commit('SET_SHOW_LOADING', true);
		return new Promise((resolve, reject) => {
			HTTP.post(`/product-management/addons`, payload, {
				header: { 'Content-Type': 'multipart/form-data' },
			})
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Addon Created');
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				})
				.finally(() => commit('SET_SHOW_LOADING', false));
		});
	},
	async getAddonById(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/product-management/addons/${payload.id}`)
				.then((response) => {
					commit('SET_SINGLEADDON', response);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async updateAddonById(_, payload) {
		const id = payload.get('addonId');
		return new Promise((resolve, reject) => {
			HTTP.put(`/product-management/addons/${id}`, payload, {
				header: { 'Content-Type': 'multipart/form-data' },
			})
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Addon Status Updated');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async deleteAddonById({ commit }, payload) {
		commit('SET_SHOW_LOADING', true);
		return new Promise((resolve, reject) => {
			HTTP.delete(`/product-management/addons/${payload}`)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Addon Removed bro');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				})
				.finally(() => commit('SET_SHOW_LOADING', false));
		});
	},
	async getAddonCategories({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/parameters/addOnCategories`)
				.then((response) => {
					commit('SET_ADDONCATEGORIES', response);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	// 	'deleteAddonCategory',
	async createAddonCategory({ commit }, payload) {
		console.log(payload);
		commit('SET_SHOW_LOADING', true);
		return new Promise((resolve, reject) => {
			HTTP.post(`/parameters/addOnCategories`, payload)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Addon Created');
					resolve(response);
				})
				.catch((error) => {
					console.log(error.response);
					reject(error);
				})
				.finally(() => commit('SET_SHOW_LOADING', false));
		});
	},
	async editAddonCategory(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(`/parameters/addOnCategories/${payload.id}`, payload)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Addon Category Updated');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async deleteAddonCategory({ commit }, payload) {
		commit('SET_SHOW_LOADING', true);
		return new Promise((resolve, reject) => {
			HTTP.delete(`/parameters/addOnCategories/${payload}`)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Addon category Removed bro');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				})
				.finally(() => commit('SET_SHOW_LOADING', false));
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
