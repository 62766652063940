/* eslint-disable import/no-unresolved */
export default [
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/Home.vue'),
		meta: {
			pageTitle: 'Home',
			breadcrumb: [
				{
					text: 'Home',
					active: true,
				},
			],
		},
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/authentication/Login.vue'),
		meta: {
			layout: 'full',
			resource: 'Auth',
			redirectIfLoggedIn: true,
		},
	},
	{
		path: '/account-setting',
		name: 'account-setting',
		component: () => import('@/views/account-setting/AccountSetting.vue'),
		meta: {
			pageTitle: 'Account Settings',
			breadcrumb: [
				{
					text: 'Account Settings',
					active: true,
				},
			],
		},
	},
	{
		path: '/admin-management',
		name: 'admin-management',
		component: () => import('@/views/user-management/UserManagement.vue'),
		meta: {
			pageTitle: 'Admin Management',
			breadcrumb: [
				{
					text: 'Admin Management',
					active: true,
				},
			],
		},
	},
	{
		path: '/role-management',
		name: 'role-management',
		component: () => import('@/views/role-management/RoleManagement.vue'),
		meta: {
			pageTitle: 'Role Management',
			breadcrumb: [
				{
					text: 'Role Management',
					active: true,
				},
			],
		},
	},
	{
		path: '/category-management',
		name: 'category-management',
		component: () =>
			// eslint-disable-next-line implicit-arrow-linebreak
			import('@/views/category-management/CategoryManagement.vue'),
		meta: {
			pageTitle: 'Category Management',
			breadcrumb: [
				{
					text: 'Category Management',
					active: true,
				},
			],
		},
	},
	{
		path: '/verification/email/fail',
		name: 'failure',
		component: () => import('@/views/status-pages/failurePage.vue'),
		meta: {
			layout: 'full',
			isStatusPage: true,
		},
	},
	{
		path: '/products',
		name: 'products',
		// eslint-disable-next-line import/no-extraneous-dependencies
		component: () => import('@/views/products/ApprovedProducts.vue'),
		meta: {
			pageTitle: 'Products',
			breadcrumb: [
				{
					text: 'Home',
					active: true,
				},
			],
		},
	},
	{
		path: '/pending-products',
		name: 'pending-products',
		// eslint-disable-next-line import/no-extraneous-dependencies
		component: () => import('@/views/products/Products.vue'),
		meta: {
			pageTitle: 'Products',
			breadcrumb: [
				{
					text: 'Home',
					active: true,
				},
			],
		},
	},

	{
		path: '/verification/email/success',
		name: 'success',
		component: () => import('@/views/status-pages/successPage.vue'),
		meta: {
			layout: 'full',
			isStatusPage: true,
		},
	},
	{
		path: '/password/set',
		name: 'success',
		component: () => import('@/views/password-set/PasswordSet.vue'),
		meta: {
			layout: 'full',
			isStatusPage: true,
		},
	},
	{
		path: '/regions',
		name: 'regions',
		component: () =>
			// eslint-disable-next-line implicit-arrow-linebreak
			import('@/views/parameters/Regions.vue'),
		meta: {
			pageTitle: 'Regions',
			breadcrumb: [
				{
					text: 'Regions',
					active: true,
				},
			],
		},
	},
	{
		path: '/categories',
		name: 'categories',
		component: () =>
			// eslint-disable-next-line implicit-arrow-linebreak
			import('@/views/parameters/Categories.vue'),
		meta: {
			pageTitle: 'Categories',
			breadcrumb: [
				{
					text: 'Categories',
					active: true,
				},
			],
		},
	},
	{
		path: '/add-category',
		name: 'add-category',
		component: () =>
			// eslint-disable-next-line implicit-arrow-linebreak
			import('@/views/parameters/AddCategory.vue'),
		meta: {
			pageTitle: 'Add Category',
			breadcrumb: [
				{
					text: 'Categories',
					active: false,
				},
				{
					text: 'Add Category',
					active: true,
				},
			],
		},
	},
	{
		path: '/attributes',
		name: 'attributes',
		component: () =>
			// eslint-disable-next-line implicit-arrow-linebreak
			import('@/views/parameters/Attributes.vue'),
		meta: {
			pageTitle: 'Attributes',
			breadcrumb: [
				{
					text: 'Attributes',
					active: true,
				},
			],
		},
	},
	{
		path: '/addons',
		name: 'addons',
		component: () =>
			// eslint-disable-next-line implicit-arrow-linebreak
			import('@/views/addons/Addons.vue'),
		meta: {
			pageTitle: 'Addons',
			breadcrumb: [
				{
					text: 'Addons',
					active: true,
				},
			],
		},
	},
	{
		path: '/vendors',
		name: 'vendors',
		component: () => import('@/views/vendor-management/Vendors.vue'),
		meta: {
			pageTitle: 'Vendors',
			breadcrumb: [
				{
					text: 'Vendors',
					active: true,
				},
			],
		},
	},
	{
		path: '/applications',
		name: 'applications',
		component: () => import('@/views/vendor-management/Applications.vue'),
		meta: {
			pageTitle: 'Applications',
			breadcrumb: [
				{
					text: 'Applications',
					active: true,
				},
			],
		},
	},
	{
		path: '/customers',
		name: 'customers',
		component: () => import('@/views/customer-management/Customers.vue'),
		meta: {
			pageTitle: 'Customers',
			breadcrumb: [
				{
					text: 'Customers',
					active: true,
				},
			],
		},
	},
	{
		path: '/orders',
		name: 'orders',
		component: () => import('@/views/order-management/Orders.vue'),
		meta: {
			pageTitle: 'Orders',
			breadcrumb: [
				{
					text: 'Orders',
					active: true,
				},
			],
		},
	},
	{
		path: '/error-404',
		name: 'error-404',
		component: () => import('@/views/error/Error404.vue'),
		meta: {
			layout: 'full',
		},
	},
	{
		path: '*',
		redirect: 'error-404',
	},
];
