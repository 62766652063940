/* eslint-disable */
import axios from '@axios';
import { HTTP } from '@/libs/axios';
import { errorsHandler, successMessage } from '@/helpers/errorsHandler.js';

const state = () => {
	return {
		products: [],
		algorithmDescriptors: [],
	};
};

const getters = {};

const mutations = {
	SET_PRODUCTS(state, response) {
		state.products = response.data.data;
	},
	SET_DESCRIPTORS(state, response) {
		state.algorithmDescriptors = response.data.data.descriptors;
	},
};

const actions = {
	async getProducts({ commit }, payload) {
		let params = payload.take ? 'take=' + payload.take : 'take=-1';
		params += payload.skip ? '&skip=' + payload.skip : '&skip=0';
		params += payload.searchKey ? '&searchKey=' + payload.searchKey : '';

		return new Promise((resolve, reject) => {
			HTTP.get(`/product-management/${payload.productType}?` + params)
				.then((response) => {
					commit('SET_PRODUCTS', response);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async deleteProductById({ commit }, id) {
		return new Promise((resolve, reject) => {
			HTTP.delete(`/product-management/review-products/${id}`)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async getAlgorithmDescriptors({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/parameters/algorithmDescriptors`)
				.then((response) => {
					commit('SET_DESCRIPTORS', response);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async setProductStatus(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(
				`/product-management/review-products/${payload.id}`,
				payload.data
			)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Product Status Updated');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
