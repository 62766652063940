/* eslint-disable */
import axios from '@axios';
import { HTTP } from '@/libs/axios';
import { errorsHandler, successMessage } from '@/helpers/errorsHandler.js';

const state = () => {
	return {
		userData: {
			firstName: '',
			lastName: '',
			email: '',
			mobile: '',
			logo: null,
		},
		error: null,
	};
};

const getters = {};

const mutations = {
	SET_USER_INFO(state, response) {
		if (response.data.error) {
			state.error = response.data.error;
		} else {
			state.userData = {
				firstName: response.data.data.info.firstName,
				lastName: response.data.data.info.lastName,
				email: response.data.data.info.email,
				mobile: response.data.data.info.mobile,
				logo: response.data.data.info.logoUrl,
				roles: response.data.data.info.roles,
				isSuper: response.data.data.info.isSuper,
			};
		}
	},
};

const actions = {
	async getUserInfo({ commit }, _) {
		return new Promise((resolve, reject) => {
			HTTP.get(`/profile/info`)
				.then((response) => {
					commit('SET_USER_INFO', response);
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async updateUserInfo(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(`/profile/info`, {
				firstName: payload.firstName,
				lastName: payload.lastName,
				mobile: payload.mobile,
			})
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Profile Info Updated');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async uploadImage(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.put(`/profile/info/logo`, payload, {
				header: { 'Content-Type': 'multipart/form-data' },
			})
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Profile Image Uploaded');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
	async deleteImage(_, payload) {
		return new Promise((resolve, reject) => {
			HTTP.delete(`/profile/info/logo`)
				.then((response) => {
					if (response.data.error) errorsHandler(response.data.error);
					else successMessage('Successfully', 'Profile Image Deleted');
					resolve(response);
				})
				.catch((error) => {
					console.log(error);
					reject(error);
				});
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
